import * as _creditCardType2 from "credit-card-type";

var _creditCardType = "default" in _creditCardType2 ? _creditCardType2.default : _creditCardType2;

import _cardholderName from "./cardholder-name";
import _cardNumber from "./card-number";
import _expirationDate from "./expiration-date";
import _expirationMonth from "./expiration-month";
import _expirationYear from "./expiration-year";
import _cvv from "./cvv";
import _postalCode from "./postal-code";
var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

var creditCardType = __importStar(_creditCardType);

var cardholder_name_1 = _cardholderName;
var card_number_1 = _cardNumber;
var expiration_date_1 = _expirationDate;
var expiration_month_1 = _expirationMonth;
var expiration_year_1 = _expirationYear;
var cvv_1 = _cvv;
var postal_code_1 = _postalCode;
var cardValidator = {
  creditCardType: creditCardType,
  cardholderName: cardholder_name_1.cardholderName,
  number: card_number_1.cardNumber,
  expirationDate: expiration_date_1.expirationDate,
  expirationMonth: expiration_month_1.expirationMonth,
  expirationYear: expiration_year_1.expirationYear,
  cvv: cvv_1.cvv,
  postalCode: postal_code_1.postalCode
};
exports = cardValidator;
export default exports;