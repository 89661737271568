var exports = {};
// Polyfill taken from <https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray#Polyfill>.
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = void 0;

exports.isArray = Array.isArray || function (arg) {
  return Object.prototype.toString.call(arg) === "[object Array]";
};

export default exports;