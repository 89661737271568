var exports = {};

function luhn10(identifier) {
  var sum = 0;
  var alt = false;
  var i = identifier.length - 1;
  var num;

  while (i >= 0) {
    num = parseInt(identifier.charAt(i), 10);

    if (alt) {
      num *= 2;

      if (num > 9) {
        num = num % 10 + 1; // eslint-disable-line no-extra-parens
      }
    }

    alt = !alt;
    sum += num;
    i--;
  }

  return sum % 10 === 0;
}

exports = luhn10;
export default exports;