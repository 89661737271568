import _parseDate from "./lib/parse-date";
import _expirationMonth from "./expiration-month";
import _expirationYear from "./expiration-year";
var exports = {};

var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expirationDate = void 0;
var parse_date_1 = _parseDate;
var expiration_month_1 = _expirationMonth;
var expiration_year_1 = _expirationYear;

function verification(isValid, isPotentiallyValid, month, year) {
  return {
    isValid: isValid,
    isPotentiallyValid: isPotentiallyValid,
    month: month,
    year: year
  };
}

function expirationDate(value, maxElapsedYear) {
  var date;

  if (typeof value === "string") {
    value = value.replace(/^(\d\d) (\d\d(\d\d)?)$/, "$1/$2");
    date = parse_date_1.parseDate(String(value));
  } else if (value !== null && typeof value === "object") {
    var fullDate = __assign({}, value);

    date = {
      month: String(fullDate.month),
      year: String(fullDate.year)
    };
  } else {
    return verification(false, false, null, null);
  }

  var monthValid = expiration_month_1.expirationMonth(date.month);
  var yearValid = expiration_year_1.expirationYear(date.year, maxElapsedYear);

  if (monthValid.isValid) {
    if (yearValid.isCurrentYear) {
      var isValidForThisYear = monthValid.isValidForThisYear;
      return verification(isValidForThisYear, isValidForThisYear, date.month, date.year);
    }

    if (yearValid.isValid) {
      return verification(true, true, date.month, date.year);
    }
  }

  if (monthValid.isPotentiallyValid && yearValid.isPotentiallyValid) {
    return verification(false, true, null, null);
  }

  return verification(false, false, null, null);
}

exports.expirationDate = expirationDate;
export default exports;