var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cvv = void 0;
var DEFAULT_LENGTH = 3;

function includes(array, thing) {
  for (var i = 0; i < array.length; i++) {
    if (thing === array[i]) {
      return true;
    }
  }

  return false;
}

function max(array) {
  var maximum = DEFAULT_LENGTH;
  var i = 0;

  for (; i < array.length; i++) {
    maximum = array[i] > maximum ? array[i] : maximum;
  }

  return maximum;
}

function verification(isValid, isPotentiallyValid) {
  return {
    isValid: isValid,
    isPotentiallyValid: isPotentiallyValid
  };
}

function cvv(value, maxLength) {
  if (maxLength === void 0) {
    maxLength = DEFAULT_LENGTH;
  }

  maxLength = maxLength instanceof Array ? maxLength : [maxLength];

  if (typeof value !== "string") {
    return verification(false, false);
  }

  if (!/^\d*$/.test(value)) {
    return verification(false, false);
  }

  if (includes(maxLength, value.length)) {
    return verification(true, true);
  }

  if (value.length < Math.min.apply(null, maxLength)) {
    return verification(false, true);
  }

  if (value.length > max(maxLength)) {
    return verification(false, false);
  }

  return verification(true, true);
}

exports.cvv = cvv;
export default exports;