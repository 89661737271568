var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expirationYear = void 0;
var DEFAULT_VALID_NUMBER_OF_YEARS_IN_THE_FUTURE = 19;

function verification(isValid, isPotentiallyValid, isCurrentYear) {
  return {
    isValid: isValid,
    isPotentiallyValid: isPotentiallyValid,
    isCurrentYear: isCurrentYear || false
  };
}

function expirationYear(value, maxElapsedYear) {
  if (maxElapsedYear === void 0) {
    maxElapsedYear = DEFAULT_VALID_NUMBER_OF_YEARS_IN_THE_FUTURE;
  }

  var isCurrentYear;

  if (typeof value !== "string") {
    return verification(false, false);
  }

  if (value.replace(/\s/g, "") === "") {
    return verification(false, true);
  }

  if (!/^\d*$/.test(value)) {
    return verification(false, false);
  }

  var len = value.length;

  if (len < 2) {
    return verification(false, true);
  }

  var currentYear = new Date().getFullYear();

  if (len === 3) {
    // 20x === 20x
    var firstTwo = value.slice(0, 2);
    var currentFirstTwo = String(currentYear).slice(0, 2);
    return verification(false, firstTwo === currentFirstTwo);
  }

  if (len > 4) {
    return verification(false, false);
  }

  var numericValue = parseInt(value, 10);
  var twoDigitYear = Number(String(currentYear).substr(2, 2));
  var valid = false;

  if (len === 2) {
    if (String(currentYear).substr(0, 2) === value) {
      return verification(false, true);
    }

    isCurrentYear = twoDigitYear === numericValue;
    valid = numericValue >= twoDigitYear && numericValue <= twoDigitYear + maxElapsedYear;
  } else if (len === 4) {
    isCurrentYear = currentYear === numericValue;
    valid = numericValue >= currentYear && numericValue <= currentYear + maxElapsedYear;
  }

  return verification(valid, valid, isCurrentYear);
}

exports.expirationYear = expirationYear;
export default exports;